import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonAuth from "../../Button/ButtonAuth/ButtonAuth"

import { useForm, Controller, useFormContext } from "react-hook-form";
import './RegisterStore.scss'
import storeApi from "../../../api/storeApi";
import {
  FormLabel,
  FormControl,
  InputLabel,
  Input,
  Box,
  Select,
  MenuItem,
  Modal,
  Button,
  Grid,
} from "@mui/material";

import {
  getCity,
  getDistrict,
  getWard,
  createNewAddress,
} from "../../../redux/reducers/address";

import ImageUpload from "../../../assets/images/upload/uploadImage.jpg";

import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "../../../helpers/formSchema";

import uploadApi from "../../../api/uploadImage";
import { toast } from "react-toastify";
import * as yup from "yup";
import { set } from "nprogress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const schema = yup.object({
  nameStore: formSchema.nameStore,
  email: formSchema.email,
  phoneNumber: formSchema.phone,
  desc: formSchema.desc,
  uploadImage: formSchema.uploadImage,
  cities: formSchema.cities,
  district: formSchema.district_code,
  ward: formSchema.ward_code,
});

// const defaultValues = {
//   email: "",
//   nameStore: "",
//   phoneNumber: "",
//   desc: "",
// };

const RegisterStore = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getCity());
  }, []);
  const { city, district, ward, addAddress } = useSelector(
    (state) => state.addressReducer
  );

  // const [wardd,setWardd] = useState(ward)



  const dispatch = useDispatch();
  //set state Selected
  const [cityAddress, setCityAddress] = useState();
  const [districtsAddress, setDistrictsAddress] = useState("");
  const [wardAddress, setWardAddress] = useState("");

  //convert variable put to dataForm
  const [cityAddressConvert, setCityAddressConvert] = useState("");
  const [districtsAddressConvert, setDistrictsAddressConvert] = useState("");
  const [wardAddressConvert, setWardAddressConvert] = useState("");
  const [specific,setSpecific] = useState()

  const [dataForm, setDataForm] = useState({});
  const onChangeCity = (e) => {
    dispatch(getDistrict(e.target.value));
    setCityAddress(e.target.value);
    setDistrictsAddress([]);
    // setWardd([]);
    setWardAddress([] )
  };

  const onChangeDistricts = (e) => {
    dispatch(getWard(e.target.value));
    setDistrictsAddress(e.target.value);
    // setWardd(ward)
    setWardAddress([]);
  };
  const onChangeWard = (e) => {
    setWardAddress(e.target.value);
  };

  const districtFilter = district.find((c) => c.id == districtsAddress);
  const wardFilter = ward.find((c) => c.id == wardAddress);

  const found = city.find(obj => {
    return obj.id == cityAddress;
  });
  // console.log('x',found)
  useEffect(() => {
    // setCityAddressConvert(city[cityAddress - 1]);
    setCityAddressConvert(found);
    setDistrictsAddressConvert(districtFilter);
    setWardAddressConvert(wardFilter);
  }, [cityAddress, districtsAddress, wardAddress]);

  useEffect(() => {
    setDataForm({
      address: {
        address: specific,
        city: {
          id: cityAddressConvert?.id,
          name: cityAddressConvert?.name,
          ghn_id: cityAddressConvert?.ghn_id,
        },
        district: {
          id: districtsAddressConvert?.id,
          name: districtsAddressConvert?.name,
          ghn_id: districtsAddressConvert?.ghn_id,
        },
        ward: {
          id: wardAddressConvert?.id,
          name: wardAddressConvert?.name,
          ghn_id: wardAddressConvert?.ghn_id,
        },
      },
    });
    // }
  }, [
    cityAddressConvert,
    districtsAddressConvert,
    wardAddressConvert,
    cityAddress,
    districtsAddress,
    wardAddress,
    specific
  ]);
  useEffect(() => {
    (async function () {
      if (!districtFilter) return;
    })();
  }, [districtFilter]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm(
  //     {
  //   resolver: yupResolver(schema),
  //   defaultValues,
  // }
  );


  const [value, setValue] = React.useState([])
  const useUpload = async (e) => {
    let formData = new FormData();

    for (const key of Object.keys(e.target.files)) {
      formData.append("files", e.target.files[key]);
    }
    try {
      const res = await uploadApi.uploadArray(formData);
      setValue({ ...value, [e.target.name]: res.url });
      if ((res.message = "Success")) {
        toast.success("Upload ảnh thành thành công");
      }
    } catch (error) {
      toast.error("Upload ảnh không thành công");
    }
  };

  const onsubmit = (data) => {
    const address = dataForm.address
    data.address = address
    data.image_url = [value.image_url?.toString()]
    data.ward_code = address.ward.ghn_id
    data.district_id = address.district.ghn_id
    try{
        const register = storeApi.registerStore(data)
        if(register?.message === "Success"){
          toast.success("Tạo cửa hàng thành công")
          if(localStorage.getItem('checked') !== null && localStorage.getItem('checked') === "true"){
            localStorage.removeItem('user');
            localStorage.removeItem('access_Token');
            localStorage.removeItem('refresh_Token');
          }else{
            localStorage.clear()
          }
          window.location.reload();
        }
    }catch (e) {
      console.log(e)
    }
  };


  return (
    <div>
      <p onClick={handleOpen} className="header-top-text-pc">
          BÁN HÀNG CÙNG ORGANIC
      </p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="box-main-login">
            <div className="box-main-title">
              <p>Đăng Ký Bán Hàng</p>
            </div>
            <div className="box-main-form">
              <form>
                <Grid container spacing={5} columns={16}>
                  <Grid item xs={8}>
                    <FormControl
                        fullWidth={true}
                        margin="normal"
                        error={errors.nameStore}
                    >
                      <FormLabel htmlFor="nameStore">Tên cửa hàng</FormLabel>
                      <input
                          id="name"
                          placeholder="Tên cửa hàng"
                          {...register("name",{required:"Tên cửa hàng không được để trống"})}
                          control={control}
                      />
                      <p
                          style={{
                            color: "red",
                          }}
                      >
                        {errors.name?.message}
                      </p>
                    </FormControl>

                    <FormControl fullWidth={true} error={errors.email}>
                      <FormLabel htmlFor="mail">Email</FormLabel>
                      <input
                          id="email"
                          placeholder="Email"
                          {...register("email",{required:"email không được để trống"})}
                          control={control}
                      />
                      <p
                          style={{
                            color: "red",
                          }}
                      >
                        {errors.email?.message}
                      </p>
                    </FormControl>

                    <FormControl fullWidth={true} error={errors.phoneNumber}>
                      <FormLabel htmlFor="phoneNumber">Số điện thoại</FormLabel>
                      <input
                          id="phone"
                          placeholder="Số điện thoại"
                          {...register("phone",{required:"Phone không được để trống"})}
                          control={control}
                      />
                      <p
                          style={{
                            color: "red",
                          }}
                      >
                        {errors.phone?.message}
                      </p>
                    </FormControl>

                    <FormControl fullWidth={true} error={errors.desc}>
                      <FormLabel htmlFor="desc">Mô tả cửa hàng</FormLabel>
                      <input
                          id="desc"
                          placeholder="Mô tả cửa hàng"
                          {...register("des")}
                          control={control}
                      />
                      <p
                          style={{
                            color: "red",
                          }}
                      >
                        {errors.desc?.message}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} style={{marginTop:'15px'}}>
                    <FormControl fullWidth={true}>
                      <FormLabel>Tỉnh/Thành phố</FormLabel>
                      <select
                          id="cars"
                          onChange={onChangeCity}
                          value={cityAddress}
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingLeft: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                      >
                        <option>Tỉnh/Thành phố</option>
                        {city.map((item) => (
                            <option value={item.id} key={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </FormControl>
                    <FormControl fullWidth={true} style={{marginTop:'22px'}}>
                      <FormLabel>Quận/Huyện</FormLabel>
                      <select
                          id="cars"
                          onChange={onChangeDistricts}
                          value={districtsAddress}
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingLeft: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                      >
                        <option>Quận/Huyện</option>
                        {district.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </FormControl>
                    <FormControl fullWidth={true} style={{marginTop:'14px'}}>
                      <FormLabel>Phường/Xã</FormLabel>
                      <select
                          id="cars"
                          onChange={onChangeWard}
                          value={wardAddress}
                          style={{
                            height: "40px",
                            width: "100%",
                            paddingLeft: "10px",
                            border: "1px solid #c4c4c4",
                            borderRadius: "5px",
                          }}
                      >
                        <option> Phường/Xã</option>
                        {ward.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </FormControl>

                    <FormControl fullWidth={true} style={{marginTop:'16px'}}>
                      <FormLabel>Địa chỉ cụ thể</FormLabel>
                      <input
                          onChange={(e)=>setSpecific(e?.target?.value)}
                          control={control}
                      />
                      <p
                          style={{
                            color: "red",
                          }}
                      >
                        {errors.desc?.message}
                      </p>
                    </FormControl>
                    <FormControl fullWidth={true}>
                      <FormLabel>Ảnh đại diện</FormLabel>
                      <Box
                          sx={{
                            display: "flex",
                          }}
                      >
                        <FormLabel htmlFor="idUpload">
                          <img
                              alt="Upload"
                              src={ImageUpload}
                              width="50px"
                              height="50px"
                          />
                        </FormLabel>
                        <input
                            onChange={useUpload}
                            type="file"
                            name="image_url"
                            accept="image/*"
                            id="idUpload"
                            style={{ display: "none" }}
                            layout="fill"
                            // multiple
                        />
                        {value.image_url && (
                            <Box
                                sx={{
                                  display: "flex",
                                }}
                            >
                              {value.image_url.map((image, index) => (
                                  <img
                                      src={`${process.env.REACT_APP_API_URL}/${image}`}
                                      alt="error"
                                      key={index}
                                      width="50px"
                                      height="50px"
                                  />
                              ))}
                            </Box>
                        )}
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>

                <ButtonAuth text={"Đăng ký"} onClick={handleSubmit(onsubmit)}/>
                {/*<span*/}
                {/*  // onClick={() => onChangeNewAddress(dataForm)}*/}
                {/*   onClick={handleSubmit(onsubmit)}*/}
                {/*>*/}
                {/*  Đăng Ký*/}
                {/*</span>*/}
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default RegisterStore;
