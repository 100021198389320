import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormRegister from "../../components/Expo/FormRegister";
import HeaderExpo from "../../components/Expo/HeaderExpo";
import { getCountry, registerStall } from "../../redux/reducers/expo";
import Footer from "../layouts/footer/Footer";
import HeaderDetail from "../layouts/headerDetail/HeaderDetail";

const RegisterVisit = () => {
  const [countryId, setCountryId] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCountry());
  }, [id]);

  const { country } = useSelector((state) => state.expoReducer);

  const changeCountry = (data) => {
    setCountryId(data.target.value);
  };

  const onsubmit = (data) => {
    data.country_id = parseInt(countryId);
    data.event_id = parseInt(id);
    data.type = 2;
    window.scrollTo(0, 0);
  };

  return (
    <>
      <HeaderDetail />
      <HeaderExpo text={"Triển lãm trong nước / Đăng ký tham quan"} />
      <div className="container">
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="boxForm">
            <p className="title">Thông tin doanh nghiệp</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"name_enterprise"}
                  label={"Tên doanh nghiệp"}
                  placeholder={"Nhập tên doanh nghiệp"}
                />
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"address"}
                  label={"Địa chỉ"}
                  placeholder={"Nhập địa chỉ"}
                />
              </div>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "start",
                    marginBottom: "29px",
                  }}
                >
                  <label style={{ color: "rgb(78, 172, 43)" }}>Quốc tịch</label>
                  <select
                    style={{
                      height: "36px",
                      borderRadius: "5px",
                      borderColor: "#ced4da",
                    }}
                    onChange={changeCountry}
                  >
                    <option>Chọn quốc tịch</option>
                    {country?.map((it) => (
                      <option value={it?.id} key={it?.id}>
                        {it?.name}
                      </option>
                    ))}
                  </select>
                </span>{" "}
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"website"}
                  label={"Website"}
                  placeholder={"Nhập website"}
                />
              </div>
            </div>
          </div>
          <div className="boxForm">
            <p className="title">Thông tin người đại diện</p>{" "}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"full_name"}
                  label={"Họ tên"}
                  placeholder={"Nhập họ tên"}
                />
                <FormRegister
                  rules={{
                    required: "Vui lòng nhập trường này",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email không đúng định dạng",
                    },
                  }}
                  control={control}
                  name={"email"}
                  label={"Email"}
                  placeholder={"Nhập email"}
                />
              </div>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"position"}
                  label={"Chức vụ"}
                  placeholder={"Nhập chức vụ"}
                />
                <FormRegister
                  rules={{
                    required: "Vui lòng nhập trường này",
                    pattern: {
                      value:
                        /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
                      message: "Số điện thoại không đúng định dạng",
                    },
                  }}
                  control={control}
                  name={"phone"}
                  label={"Số điện thoại"}
                  placeholder={"Nhập số điện thoại"}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "80px" }}>
            <span
              style={{
                padding: "10px 20px",
                background: "#4EAC2B",
                color: "white",
                cursor: "pointer",
                borderRadius: "4px",
              }}
              onClick={handleSubmit(onsubmit)}
            >
              Đăng ký
            </span>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default RegisterVisit;
