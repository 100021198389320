import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentDetail from '../../components/Training/ContentDetail';
import { getCourseDetail } from '../../redux/reducers/course';
import Footer from '../layouts/footer/Footer';
import HeaderDetail from '../layouts/headerDetail/HeaderDetail';

const Detail = () => {
  const {id} = useParams()
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getCourseDetail(id))
  },[id])

  const { courseDetail } = useSelector((state) => state.courseReducer)

  return (
    <div>
      <HeaderDetail/>
      <div>
        <p className='container' style={{color:'#4EAC2B',padding:'10px 45px',textAlign:'left'}}>Đào tạo cho người nông dân / Khóa học / Chi tiết khóa học </p>
        <div className='container' style={{padding:'20px 45px'}}>
          <p style={{
            margin:'0',
            borderBottom:'1px solid #F47319',
            textAlign:'left',
            color: "#F47319",
            fontSize:'20px',
            fontWeight:'500'
          }}>Khóa học</p>
          <ContentDetail data={courseDetail}/>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Detail;