import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./LoginNormal.scss";
import userApi from "../../../../api/userApi";
import { Alert, notification } from "antd";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { openNotification } from "../../../Alert/AlertProduct";

// import { ToastContainer, toast } from 'react-toastify';
import { login, setError } from "../../../../redux/reducers/user";

function LoginNormal({ password, username , checked}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.userReducer);

  const handleSubmit = () => {
    const data = {
      user_name: username,
      password: password,
    };
    if (username.length === 0 || password.length === 0) {
      openNotification("error", "Vui lòng nhập đủ thông tin ");
      return;
    }
    try {
      dispatch(login(data));
      localStorage.setItem('account',JSON.stringify({"user_name":username,"password":btoa(password)}))
      localStorage.setItem('checked',checked)
    } catch (error) {
      console.log(error);
      openNotification("error", "Lỗi rùi ");
    }
  };

  return (
    <>
      {
        loading ? <div
          className="loginnormal"
          onClick={() => handleSubmit()}
        >
          Loading
        </div> : <div
          className="loginnormal"
          onClick={() => handleSubmit()}
        >
          Đăng Nhập
        </div>
      }

    </>
  );
}

export default React.memo(LoginNormal);
