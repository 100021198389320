import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import expoApi from "../../api/expoApi";

export const getExpo = createAsyncThunk("expo/getExpo", async (data) => {
  const result = await expoApi.getExpo(data);
  return result;
});
export const getExpoDetail = createAsyncThunk(
  "expo/getExpoDetail",
  async (id) => {
    const result = await expoApi.getExpoDetail(id);
    return result;
  }
);
export const getCountry = createAsyncThunk("expo/getCountry", async (id) => {
  const result = await expoApi.getCountry();
  return result;
});

export const registerStall = createAsyncThunk(
  "expo/registerStall",
  async (data) => {
    const result = await expoApi.registerStall(data);
    if (result.status == 200) {
      toast.success("Vui lòng truy cập vào gmail");
    }
    return result;
  }
);

const expoSlide = createSlice({
  name: "expo",
  initialState: {
    listExpo: [],
    country: [],
    loading: false,
    expoDetail: null,
  },
  reducers: {},
  extraReducers: {
    [getExpo.pending]: (state) => {
      state.loading = true;
    },
    [getExpo.rejected]: (state) => {
      state.loading = false;
    },
    [getExpo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listExpo = payload.data;
    },
    [getExpoDetail.pending]: (state) => {
      state.loading = true;
    },
    [getExpoDetail.rejected]: (state) => {
      state.loading = false;
    },
    [getExpoDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expoDetail = payload.data;
    },
    [getCountry.pending]: (state) => {
      state.loading = true;
    },
    [getCountry.rejected]: (state) => {
      state.loading = false;
    },
    [getCountry.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.country = payload.data;
    },
  },
});

export default expoSlide;
