import React from "react";
import "./HeaderBody.scss";
import HeaderNav from "../../../../components/Nav/HeaderNav/HeaderNav";
import { Link } from "react-router-dom";
import HeaderDropdown from "../../../../components/Nav/HeaderDropdown/HeaderDropdown";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../../../assets/images/logo.png"
import Search from "../../../../components/Search/Search";
function HeaderBody() {
  return (
    <>
      <div style={{display:'flex',margin:'20px'}}>
        <div  style={{
          marginLeft: "30px",
          display:'flex',
          width: "calc(100% - 1%)",
        }}>
          <div>
            <Link to="/" className="vietindustry">
              <img src={logo}/>
            </Link>
          </div>
          <Search/>
        </div>
        <HeaderDropdown />
      </div>
      <HeaderNav />
    </>
  );
}

export default HeaderBody;
