import React from "react";
import HeaderExpo from "../../components/Expo/HeaderExpo";
import Footer from "../layouts/footer/Footer";
import HeaderDetail from "../layouts/headerDetail/HeaderDetail";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import image from "../../assets/images/supplier/image.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpoDetail } from "../../redux/reducers/expo";
import formatDate from "../../utils/formatDate";
const DomesticFairsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getExpoDetail(id));
  }, []);
  const { expoDetail } = useSelector((state) => state.expoReducer);

  return (
    <div>
      <HeaderDetail />
      <HeaderExpo />
      <div className="container">
        <img
          src={`${process.env.REACT_APP_API_URL}/${expoDetail?.image_url[0]?.url}`}
          alt=""
          width={"100%"}
          style={{ borderRadius: "14px" }}
        />
        <p
          style={{
            fontSize: "25px",
            fontWeight: "500",
            color: "#F47319",
            textAlign: "left",
          }}
        >
          {expoDetail?.name}
        </p>
        <p style={{ display: "flex", gap: "20px" }}>
          <span>
            Đăng bởi: <span style={{ color: "#F47319" }}>The Mint</span>
          </span>{" "}
          <span style={{ color: "#369313" }}>
            {" "}
            <span
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
                background: "#369313",
              }}
            />{" "}
            Đang diễn ra
          </span>
        </p>
        <p style={{ textAlign: "left" }}>20/10/2022 - 09:00 -&gt; 11:00</p>

        <div style={{ display: "flex", gap: "20px", color: "white" }}>
          <span
            style={{
              padding: "10px",
              background: "#4EAC2B",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/register-stall/${id}`)}
          >
            Đăng ký gian hàng
          </span>
          <span
            style={{
              padding: "10px",
              background: "#4EAC2B",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/register-visit/${id}`)}
          >
            Đăng ký tham quan
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "40px",
            marginTop: "100px",
          }}
        >
          <div
            style={{
              backgroundColor: "#4EAC2B",
              width: "460px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "-11px", left: "40%" }}>
              <span
                style={{
                  padding: "40px 37px",
                  background: "#fff",
                  borderRadius: "50%",
                  border: "1px solid #BDBDBD",
                }}
              >
                <LocationOnOutlinedIcon style={{ color: "#4EAC2B" }} />
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "#fff",
                gap: "10px",
              }}
            >
              <span style={{ fontSize: "28px" }}>Địa chỉ</span>
              <span style={{ fontSize: "20px" }}>
                đường Võ Văn Kiệt - Thành phố Vĩnh Long
              </span>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#F47319",
              width: "460px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "-11px", left: "40%" }}>
              <span
                style={{
                  padding: "40px 37px",
                  background: "#fff",
                  borderRadius: "50%",
                  border: "1px solid #BDBDBD",
                }}
              >
                <CalendarMonthOutlinedIcon style={{ color: "#F47319" }} />
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                color: "#fff",
                gap: "10px",
              }}
            >
              <span style={{ fontSize: "28px" }}>Thời gian</span>
              <span style={{ fontSize: "20px" }}>
                {formatDate(expoDetail?.start_time, "DD")} -
                {formatDate(expoDetail?.end_time, "DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "770px",
            marginTop: "125px",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "relative",
            opacity: "0.6",
            color: "#0D0E43",
          }}
        >
          <p style={{ fontSize: "20px" }}>MỤC ĐÍCH - Ý NGHĨA SỰ KIỆN</p>
          <div>
            <span>
              Với chủ đề: “Phát triển bền vững Nông nghiệp - Nông dân - Nông
              thôn Việt Nam”, Festival lần IV nhằm: Giới thiệu lịch sử hình
              thành và phát triển sản xuất cây lúa vùng Đồng bằng sông Cửu Long
              đến bạn bè trong nước và quốc tế. Quảng bá những thành tựu, phát
              triển ngành nông nghiệp Việt Nam sau 30 năm đổi mới với vai trò là
              ngành kinh tế chủ lực trong chiến lược phát triển kinh tế Việt
              Nam. Dự kiến có 800 gian hàng tham gia triển lãm giới thiệu thành
              tựu, tiềm năng, thế mạnh phát triển Kinh tế - Văn hóa - Xã hội của
              các tỉnh, thành vùng Đồng bằng sông Cửu Long đến các tỉnh, thành
              phố trong cả nước.
            </span>
          </div>
          <p style={{ fontSize: "20px", marginTop: "15px" }}>
            CHUỖI HOẠT ĐỘNG TẠI HỘI CHỢ
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DomesticFairsDetail;
