import React from "react";
import moment from "moment";
import "./ContentDetail.scss";
import { useNavigate } from "react-router-dom";
const ContentDetail = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="gallery">
        {data?.image_url.map((it, index) => (
          <figure className={`gallery__item gallery__item--${index}`}>
            <img
              width={"100%"}
              className="gallery__img"
              src={`${process.env.REACT_APP_API_URL}${it?.url}`}
              alt={data?.name}
            />
          </figure>
        ))}
      </div>
      <span
        style={{
          display: "flex",
          fontSize: "20px",
          fontWeight: "500",
          marginTop: "10px",
        }}
      >
        {data?.name}
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            textAlign: "left",
            gap: "40px",
            marginTop: "10px",
          }}
        >
          <div>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#737373" }}
            >
              Ngày đăng: {moment(data?.created_date).format("DD/MM/YYYY")}
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#737373" }}
            >
              Thời gian học: 6 tháng
            </p>
          </div>
          <div>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#737373" }}
            >
              Đối tượng: Nam
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#737373" }}
            >
              Số lượng thành viên: {data?.amount_min} - {data?.amount_max} người
            </p>
          </div>
        </div>
        <div>
          <span
            onClick={() => navigate(`/register-course/${data?.id}`)}
            style={{
              padding: "12px 14px",
              background: "#4EAC2B",
              borderRadius: "4px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            Tham gia
          </span>
        </div>
      </div>
      <div style={{ textAlign: "justify", marginTop: "10px" }}>
        {data?.content}
      </div>
    </div>
  );
};

export default ContentDetail;

