import React, { useState } from "react";
import "./Register.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import userApi from "../../../api/userApi";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StepBar from "../../StepBar/StepBar";
import back from "../../../assets/images/icons/back.png";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Login from "../Login/Login";

import { Collapse } from "antd";
import VerifyCode from "./VerifyCode";
import { toast } from "react-toastify";

import { validateEmail } from "../../../utils/getValidMessage";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const [watchStep, setWatchStep] = useState(1);

  const { Panel } = Collapse;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    // height: "65%",
    width: "720px",
    height: "662px",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius: '10px'
  };

  const stylesVerify = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    // height: "65%",
    width: "720px",
    height: "662px",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "7px",
    boxShadow: 24,
    paddingBottom: 4,
    paddingTop: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };

  // const validateEmail = (email) => {
  //   return String(email).toLowerCase().match(emailformat);
  // };
  const [openPhone, setOpenPhone] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openEmptyPass, setOpenEmptyPass] = useState(false);
  const [openCmPass, setOpenCmPass] = useState(false);
  const [openCheckPass, setOpenCheckPass] = useState(false);
  const [display, setDisplay] = useState(false)

  //verify
  const [openVerify, setOpenVerify] = useState(false);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [displayCode, setDisplayCode] = useState(false);

  const [textInput, setTextInput] = useState("");

  // const [showVerifyCode, setShowVerifyCode] = useState(false);

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const regex = /^(?=.{5,}\d+).*$/

  const data = {
    // user_name:username,

    //remove field email from api
    user_name: email,
    password: password,
    phone: phone,
    full_name: fullName,
  };
  const emailformat =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  // const verifyCode = async (otp) => {
  //   const res = await userApi.verifyCode(otp);
  //   if ((res.status === 500) | (res.status === 400)) {
  //     setOpenVerify(true);
  //     console.log("error api");
  //   }
  //   if (res.status === 200) {
  //     setVerifySuccess(true);
  //   }
  //   console.log(res);
  // };
  const submitForm = async () => {
    if (fullName.length === 0) {
      setOpenName(true);
      return;
    }
    if (
      phone.length !== 10 &&
      (!phone.startsWith("3", 1) ||
        !phone.startsWith("5", 1) ||
        !phone.startsWith("7", 1) ||
        !phone.startsWith("8", 1) ||
        !phone.startsWith("9", 1))
    ) {
      setOpenPhone(true);
      return;
    }
    if (!validateEmail(email)) {
      setOpenEmail(true);
      return;
    }
    if (password.length === 0) {
      setOpenEmptyPass(true);
      return;
    } else if (!regex.test(password)) {

      setOpenCheckPass(true)
      return;
    }
    if (confirmPassword.length === 0) {
      setOpenCmPass(true);
      return;
    }
    if (password !== confirmPassword) {
      setOpenPass(true);
      return;
    }

    try {
      const registerData = await userApi.registerToken(data);

      console.log('registerData', registerData);

      if (
        registerData.message == "Success" ||
        registerData.errors == "CUSTOMER_HAD_EXIST_BUT_NOT_ACTIVE"
      ) {
        toast.success("Bạn vui lòng vào email để xác nhận");
      }
      if (registerData.errors == "CUSTOMER_EXIST") {
        toast.error("Tài khoản đã được đăng ký");
        // openNotification("warning", "Tài khoản đã được đăng ký");
        // router.push("/auth/signin");
        if (registerData.errors == "CUSTOMER_MAIL_EXIST_OR_PHONE") {
          toast.error("Email hoặc số điện thoại đã tồn tại");
        }
      } else {
      }
      // console.log("register", registerData);
      return registerData;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenPass(false);
    setOpenName(false);
    setOpenEmail(false);
    setOpenPhone(false);
    setOpenVerify(false);
    setVerifySuccess(false);
    // setOpenSucess(false);
    setOpenEmptyPass(false);
    setOpenCmPass(false);
    setOpenCheckPass(false)
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <div
        style={{paddingRight:'10px',borderRight:'1px solid #737373',cursor:'pointer'}}
        onClick={handleOpen}
      >
        ĐĂNG KÝ
      </div>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleCloseForm}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          {/* {showVerifyCode && (
            <div className="box-main-login">
              <p
                onClick={() => setShowVerifyCode(!showVerifyCode)}
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                }}
              >
                Trở lại
              </p>
              <VerifyCode email={email} setOpen={setOpen} />
            </div>
          )} */}

          {/* {!showVerifyCode && (
            
          )} */}
          <div className="box-main-login">
            <div style={{display:'flex',justifyContent:'end'}}>
              <span 
                style={{border:'1px solid #4EAC2B',
                        padding:'3px 11px 5px 11px',
                        borderRadius:'50%',
                        cursor:'pointer'
                }}
                onClick={handleCloseForm}
                >x</span>
            </div>
            <div className="box-main-form">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <p style={{
                  fontSize: '32px',
                  fontWeight: '500',
                  margin: "0"
                }}>Chào mừng đến Oganic</p>
                <p
                  style={{
                    fontSize: '24px',
                    fontWeight: '500',
                    margin: "0",
                  }}
                >
                  ĐĂNG KÝ
                </p>
              </div>
              <div style={{padding:'40px 70px'}}>
                <div className="box-main-phone">
                  <div
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <input
                      style={{border:'none',borderBottom:'1px solid #4EAC2B',borderRadius:'0'}}
                      type="text"
                      placeholder="Họ và tên"
                      value={fullName}
                      required
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                    />{" "}
                  </div>
                  <div
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <input
                      style={{border:'none',borderBottom:'1px solid #4EAC2B',borderRadius:'0'}}
                      type="tel"
                      pattern="^\+84(7\d|8\d|9\d)\d{9}$"
                      required
                      placeholder="Số điện thoại"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />{" "}
                    <br />
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <input
                    style={{border:'none',borderBottom:'1px solid #4EAC2B',borderRadius:'0'}}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                    placeholder="Email"
                    required
                  />{" "}
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <input
                    style={{border:'none',borderBottom:'1px solid #4EAC2B',borderRadius:'0'}}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    // type={`${display ? 'text':'password'}`}
                    type={'text'}
                    placeholder="Nhập mật khẩu"
                  />{" "}
                  {/* <span 
                      onClick={()=>setDisplay(!display)}
                      style={{
                        position:"absolute",
                        right:'35px',
                        marginTop:'9px',
                        cursor:'pointer'
                      }}
                  ><RemoveRedEyeOutlinedIcon/></span> */}
                </div>
                {/* <br /> */}
                <div>
                  <input
                    style={{border:'none',borderBottom:'1px solid #4EAC2B',borderRadius:'0'}}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    type={`${display ? 'text' : 'password'}`}
                    placeholder="Nhập lại mật khẩu"
                  />{" "}
                  <span
                    onClick={() => setDisplay(!display)}
                    style={{
                      color:'#4EAC2B',
                      position: "absolute",
                      right: '110px',
                      marginTop: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    {display ?<VisibilityOffIcon style={{color:'#4EAC2B'}}/> :<RemoveRedEyeOutlinedIcon style={{color:'#4EAC2B'}}/>}
                  </span>
                </div>
              </div>
              {/* <br /> */}

            </div>

            <div className="box-main-login-normal" style={{padding:'0 70px'}}>
              {/* <div> */}
              {/* <Login /> */}
              {/* </div> */}
              {/* <div
                className="box-main-login-cancel handle-cancel-register"
                onClick={handleCloseForm}
              >
                Hủy bỏ
              </div> */}
              {/* <HandleRegister
                   password={password}
                   // username={username}
                   fullName={fullName}
                   confirmPassword={confirmPassword}
                   email={email}
                   phone={phone}
                 /> */}
              
              <div
                className="handle-register"
                onClick={() => {
                  submitForm();
                }}
              >
                <p>Đăng ký</p>
                <Snackbar
                  open={openName}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Họ và tên không được để trống"
                  action={action}
                />
                <Snackbar
                  open={openEmail}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Email không đúng định dạng!"
                  action={action}
                />
                <Snackbar
                  open={openEmptyPass}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Mật khẩu không được để trống"
                  action={action}
                />
                <Snackbar
                  open={openCheckPass}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Mật khẩu cần từ 6 ký tự, ít nhất một số"
                  action={action}
                />
                <Snackbar
                  open={openCmPass}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Nhập lại mật khẩu không được để trống"
                  action={action}
                />
                <Snackbar
                  open={openPass}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Mật khẩu không khớp!"
                  action={action}
                />
                <Snackbar
                  open={openPhone}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="Số điện thoại không đúng định dạng!"
                  action={action}
                />
                {/* <Snackbar
                    open={openSuccess}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Đăng ký thành công"
                    action={action}
                  /> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Register;
