import axiosClient from "./axiosClient";

const newsApi = {
  async getList(page, size) {
    const url = `/news/all-paging?page_size=${size}&page_index=${page}`;
    return axiosClient.get(url);
  },
  async getDetail(id) {
    const url = `/news/${id}`;
    return axiosClient.get(url);
  },
  async getAllDetail(id) {
    const url = `/news`;
    return axiosClient.get(url);
  },
  async getHotNews() {
    const url = `/news/hot-new/all`;
    return axiosClient.get(url);
  },
};

export default newsApi;

