import React from "react";
import "./Login.scss";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LoginGG from "./LoginGG/LoginGG";
import LoginFB from "./LoginFB/LoginFB";
import LoginNormal from "./LoginNormal/LoginNormal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {arrowleft} from "../../../assets/images/home/arrowleft.svg";
import {arrowleftcolor} from "../../../assets/images/home/arrowleftcolor.svg";
import { gapi } from "gapi-script";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ButtonAuth from "../../Button/ButtonAuth/ButtonAuth"
import { Link } from "react-router-dom";
import {useForm } from "react-hook-form";
import userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';
import { GoogleLogin } from "@react-oauth/google";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



const clientId =
  "409140970351-sjjckifs4h71ssdemf55ikirpe2ake58.apps.googleusercontent.com";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [display,setDisplay] = useState(false)

  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({ clientId: clientId, scope: "" });
  //   }
  //   gapi.load("client:auth2", start);
  // },[]);

  // console.log(gapi.auth.getToken().access_token);

  //style mui
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    // height: "65%",
    width: "720px",
    height: "556px",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius:"10px"
  };

  const [open, setOpen] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenForgot = () => {
    setOpenForgot(true);
    setOpen(false)
  };
  const handleCloseForgot = () => {
    setOpenForgot(false);
    setOpen(true)
  };


  const changeRemember = (e) => {
    setIsChecked(e.target.checked)
  }
  const acc = JSON.parse(localStorage.getItem('account'))
  useEffect(() => {
    if (localStorage.getItem('checked') !== null) {
      setIsChecked(localStorage.getItem('checked'))
    }
    if(localStorage.getItem('account') !== null){
      setUsername(acc.user_name)
      setPassword(atob(acc.password))
    }
  }, [])

  const {register,handleSubmit ,reset,formState: { errors }} = useForm()

  const onsubmit = async (data) => {
    try{
      const verifyEmail = await userApi.forgotPassword(data);
      if (verifyEmail.message == "Success") {
        toast.success("Bạn vui lòng vào email để xác nhận");
        setOpenForgot(false)
        reset()
      }
    }catch (e) {
      console.log(e)
    }
  }
  return (
    <div >
      <div style={{paddingRight:'10px',borderRight:'1px solid #737373',cursor:'pointer'}}  
      onClick={handleOpen}>
        ĐĂNG NHẬP
      </div>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }} >
          <div className="box-main-login">
            <div style={{display:'flex',justifyContent:'end'}}>
              <span 
                style={{border:'1px solid #4EAC2B',
                        padding:'3px 11px 5px 11px',
                        borderRadius:'50%',
                        cursor:'pointer'
                }}
                onClick={handleClose}
                >x</span>
            </div>
            <div style={{margin:'40px 150px'}}>
              <div style={{
                display:'flex',
                justifyContent:'center',
                fontSize:'32px',
                fontWeight:'500'
              }}>Chào mừng đến Oganic</div>
              <div className="box-main-title mb-2 mt-2">
                <p>ĐĂNG NHẬP</p>
              </div>
            </div>
            <div className="box-main-form">
              <div style={{
                marginTop: "20px",
                padding:'0 40px'
              }}>
                {/* <label>Email/Số điện thoại</label> <br /> */}
                <input
                  value={username}
                  style={{
                    border:'none',
                    borderBottom:'1px solid #4EAC2B',
                    borderRadius: '0'
                  }}
                  type="text" placeholder="Tài khoản"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  />
                  
              </div>
              <div style={{
                marginTop: "20px",
                padding:'0 40px'
              }}>
                {/* <label>Mật khẩu </label> <br /> */}
                <input
                  style={{
                    border:'none',
                    borderBottom:'1px solid #4EAC2B',
                    borderRadius: '0',
                    marginBottom: "10px"
                  }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type={`${display ? 'text':'password'}`}
                  placeholder="Mật khẩu"
                />{""}
                <span 
                    onClick={()=>setDisplay(!display)}
                    style={{
                      position:"absolute",
                      right:'85px',
                      marginTop:'9px',
                      cursor:'pointer'
                    }}
                >
                  {display ?<VisibilityOffIcon style={{color:'#4EAC2B'}}/> :<RemoveRedEyeOutlinedIcon style={{color:'#4EAC2B'}}/>}
                </span>
              </div>
            </div>
            <div className="box-forgot">
              <div className="remember">
                <input type="checkbox" checked={isChecked} onChange={changeRemember} />
                <span>Nhớ mật khẩu</span>
              </div>
              <div>
                <Link to={'/#'} onClick={
                  handleOpenForgot
                }>Quên mật khẩu</Link>
              </div>
            </div>
            <div className="box-main-form-forget" onClick={handleClose}>
              {/* <ForgotPassword setOpen={setOpen} /> */}
            </div>
            {/* <p className="box-main-or">HOẶC</p> */}
            <div style={{display:'flex',justifyContent:'center',marginLeft:'36px'}}>
              {/* <div className="box-main-login-cancel" onClick={handleClose}>
                Hủy bỏ
              </div> */}
              <LoginNormal password={password} username={username} checked={isChecked} />
            </div>
          </div>
          <div className="box-main-login-social">
              {/* not update */}
              <LoginGG />
              <LoginFB />
            </div>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={openForgot}
        onClose={handleCloseForgot}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }} style={{width:'720px',height:"393px",borderRadius:"20px"}}>
          <div className="box-forgot">
            <div className="head-form-forgot" onClick={handleCloseForgot}>
              <span className="icon-back">
                <ArrowBackIcon style={{fontSize:"13px",color:'#4EAC2B'}}/>
              </span>
              <div className="text-back">Trở lại <span className="text">Đăng nhập</span></div>
            </div>
            <div className="title">
              <p>Quên mật khẩu</p>
            </div>
            <form >
              <div className="form-input-forgot">
                <input {...register('user_name',{
                  required: "Vui lòng nhập email",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email không đúng định dạng"
                  }
                })} type="text" placeholder="Email"/>
                <ErrorMessage
                    errors={errors}
                    name="user_name"
                    render={({ message }) => <small className={"error"}>{message}</small>}
                />
                <small>Nhập lại email liên kết với tài khoản để đặt lại mật khẩu của bạn</small>
              </div>
              <ButtonAuth text={"Xác nhận"} onClick={handleSubmit(onsubmit)}/>
            </form>
          </div>

        </Box>
      </Modal>
    </div>
  );
}

export default Login;
