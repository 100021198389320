import { Col, Collapse, Input, InputNumber, Row, Space } from "antd";
import React, { useEffect } from "react";
import Footer from "../../layouts/footer/Footer";
import HeaderDetail from "../../layouts/headerDetail/HeaderDetail";
import Header from "./Header";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourseDetail,
  registerCourse,
} from "../../../redux/reducers/course";
import onePayApi from "../../../api/onepayApi";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./RegisterCourse.scss";
import { useState } from "react";
import TableLeft from "../../../components/Training/Register/TableLeft";
import { useForm } from "react-hook-form";
import createArray from "../../../utils/createArray";
import InfoTicket from "../../../components/Training/Register/InfoTicket";
import ChoosePayment from "../../../components/Training/Register/ChoosePayment";
import courseApi from "../../../api/courseApi";
const RegisterCourse = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { Panel } = Collapse;
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get("key");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [method, setMethod] = useState();

  useEffect(() => {
    dispatch(getCourseDetail(id));
    setTotal(quantity * courseDetail?.price);
    console.log("q", quantity);
  }, [id, quantity]);

  const { courseDetail } = useSelector((state) => state.courseReducer);
  const { user } = useSelector((state) => state.userReducer);
  const onChange = (value) => {
    setQuantity(value);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    const init = createArray(quantity, 0).map((i, index) => {
      return {
        index: `person-${index}`,
        course_id: id,
        contact_name: "",
        phone: "",
        email: "",
        address: "",
      };
    });
    setData(init);
  }, [quantity]);

  const onchange = (e) => {
    const result = data.map((value, index) => {
      if (e.target?.id == `person-${index}`) {
        return { ...value, [e.target.name]: e.target.value };
      } else {
        return { ...value };
      }
    });

    setData(result);
  };
  const onChangeMethod = (e) => {
    setMethod(e?.target?.value);
  };
  const onclick = async () => {
    switch (key) {
      default:
        return null;
      case null:
        window.scrollTo(0, 0);
        setSearchParams({ key: "step-question-form" });
        break;
      case "step-question-form":
        window.scrollTo(0, 0);
        setSearchParams({ key: "payment" });
        break;
      case "payment":
        const changeData = data.map((x) => {
          delete x["index"];
          return x;
        });
        const dataRegister = {
          course_id: id,
          type: "4",
          payment_method: method,
          total_price: total,
          list_details: changeData,
        };
        if (method == "1") {
          const res = await courseApi.registerCourse(dataRegister);
          if (res?.data?.id !== undefined || res?.data?.id.length !== 0) {
            const dataCheckoutOnepay = {
              order: {
                Id: res?.data?.id.toString(),
                amount: total,
                customerId: user?.data?.id.toString(),
                type: 4,
              },
              transactionType: "domestic",
            };
            const resOnePay = await onePayApi.checkoutOnePay(
              dataCheckoutOnepay
            );
            if (resOnePay.message === "error") {
            } else {
              window.location.href = resOnePay.url;
            }
          }
        }
        break;
    }
  };
  return (
    <div>
      <HeaderDetail />
      <Header title={courseDetail?.name} />
      <div className="container" style={{ marginTop: "20px" }}>
        <Row>
          <Col span={16}>
            <div className="container">
              {key === "step-question-form" ? (
                <div>
                  <h3
                    style={{
                      textAlign: "left",
                      padding: "10px",
                      borderBottom: "2px solid rgb(171, 171, 171)",
                    }}
                  >
                    Câu hỏi cho từng người tham gia
                  </h3>
                  <InfoTicket quantity={quantity} onChange={onchange} />
                </div>
              ) : key === "payment" ? (
                <ChoosePayment onChange={onChangeMethod} />
              ) : (
                <TableLeft courseDetail={courseDetail} onChange={onChange} />
              )}
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "-webkit-center" }}>
            <div
              style={{
                border: "1px solid #ABABAB",
                borderRadius: "18px",
                width: "90%",
              }}
            >
              <span>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#767676",
                    padding: "10px 0",
                    borderBottom: "2px solid rgb(171, 171, 171)",
                    margin: "0 30px",
                  }}
                >
                  Thông tin đặt vé
                </p>
              </span>
              <div className="container">
                <div
                  style={{
                    display: "flex",
                    margin: "0 20px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "start",
                      fontSize: "17px",
                      fontWeight: "500",
                      gap: "10px",
                    }}
                  >
                    <span>Họ tên:</span>
                    <span>Email:</span>
                    <span>Số điện thoại:</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "end",
                      fontSize: "15px",
                      color: "#667985",
                      gap: "10px",
                    }}
                  >
                    <span style={{ padding: "3px 0 0 0" }}>
                      {user?.data?.full_name}
                    </span>
                    <span style={{ padding: "3px 0 0 0" }}>
                      {user?.data?.email}
                    </span>
                    <span style={{ padding: "3px 0 0 0" }}>
                      {user?.data?.phone}
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <ul style={{ textAlign: "left" }}>
                    <li
                      style={{
                        fontSize: "19px",
                        fontWeight: "500",
                        marginBottom: "10px",
                      }}
                    >
                      Hình thức thanh toán{" "}
                    </li>
                    <span style={{ color: "#667985" }}>
                      {courseDetail?.price > 0 ? "Có phí" : "Miễn phí"} - Nhận
                      vé qua email
                    </span>
                    <li
                      style={{
                        fontSize: "19px",
                        fontWeight: "500",
                        marginTop: "40px",
                      }}
                    >
                      Thông tin đặt vé
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        >
                          <span>Loại vé </span>
                          <span>Số lượng </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "#767676",
                            marginTop: "20px",
                          }}
                        >
                          <span style={{ width: "70%" }}>
                            Vé mời tham gia (vé có giới hạn, vui lòng đăng ký
                            sớm)
                          </span>
                          <span>{quantity}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "40px 10px",
                  }}
                >
                  <span style={{ fontSize: "17px", fontWeight: "500" }}>
                    Tổng cộng
                  </span>
                  <span style={{ fontSize: "18px", color: "#FB2525" }}>
                    {isNaN(total) ? "0" : total.toLocaleString("de-DE")} VND
                  </span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <span
                style={{
                  color: "white",
                  padding: "10px 170px",
                  backgroundColor: "#39B54A",
                  borderRadius: "14px",
                  cursor: "pointer",
                }}
                onClick={onclick}
              >
                Tiếp tục
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};
export default React.memo(RegisterCourse);
