import React, { useEffect } from "react";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import userApi from "../../../../api/userApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginGoogle } from "../../../../redux/reducers/user";
import GG from '../../../../assets/images/icons/logo_google.png';

function LoginGG() {
  const dispatch = useDispatch()
  const handleSubmit = async (response) => {
    
    var user = jwt_decode(response.credential);
    const data = {
      user: {
        email: user.email,
        familyName: user.family_name,
        givenName: user.given_name,
        id: user.sub,
        name: user.name,
        photo: user.name,
      },
    };
    dispatch(loginGoogle(data))
};
  
  return (
    <div>
      <GoogleLogin
          onSuccess={(credentialResponse) => {
              handleSubmit(credentialResponse);
          }}
          onError={() => {
              console.log('Login Failed');
          }}
          useOneTap
      />
    </div>
  );
}

export default LoginGG;
