import React, { useEffect } from "react";
import "./HeaderNav.scss";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import BoxNav from "../../HoverNav/BoxNav";
import { useDispatch, useSelector } from "react-redux";
import { categorygetAll } from "../../../redux/reducers/category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const menuNews = [
  { id: "1", name: "Tin xuất nhập khẩu", path: "/" },
  { id: "2", name: "Bản tin thị trường", path: "/" },
  { id: "3", name: "Tin quốc tế", path: "/" },
  { id: "4", name: "Tin tổng hợp", path: "/" },
];
const menu = [
  { id: "723", name: "Hội chợ trong nước", path: "/domesticFairs/723" },
  { id: "724", name: "Hội chợ nước ngoài", path: "/foreign-fairs/724" },
];
const menu1 = [
  { id: "1", name: "Khóa học", path: "/training/course" },
  { id: "2", name: "Kiến thức nông nghiệp", path: "/training/knowledge" },
  { id: "3", name: "Hỏi đáp nghề nông", path: "/training/Q&A" },
];

function HeaderNav() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(categorygetAll);
  }, []);

  const { categoryList } = useSelector((state) => state.categoryReducer);
  return (
    <div className="header-nav ">
      <div className="header-link">
        <Link to="/">Trang chủ</Link>
      </div>
      <div className="header-link header-dropdown-menu">
        <BoxNav data={categoryList} />
        <Link to="/">Danh mục </Link>
        <span style={{ position: "absolute" }}>
          <ExpandMoreIcon />
        </span>
      </div>
      <div className="header-link header-dropdown-menu">
        <BoxNav data={menuNews} />
        <Link to="/news">Tin tức</Link>
        <span style={{ position: "absolute" }}>
          <ExpandMoreIcon />
        </span>
      </div>
      <div className="header-link header-dropdown-menu">
        <BoxNav data={menu} />
        <Link to="/">Hội chợ triển lãm</Link>
        <span style={{ position: "absolute" }}>
          <ExpandMoreIcon />
        </span>
      </div>
      <div className="header-link header-dropdown-menu">
        <BoxNav data={menu1} />
        <Link to="/training">Đào tạo cho người nông dân</Link>
        <span style={{ position: "absolute" }}>
          <ExpandMoreIcon />
        </span>
      </div>
      <div className="header-link">
        <Link to="/cooperate">Hợp tác đầu tư</Link>
      </div>
      <div className="header-link">
        <Link to="/group">Hội nhóm</Link>
      </div>

      {/* <div className="header-link">
        <Link to="/">ĐẤU THẦU</Link>
      </div>      */}
    </div>
  );
}

export default HeaderNav;
