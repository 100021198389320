import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BoxNav.scss';

const BoxNav = ({data}) => {
	const navigate = useNavigate()
	return (
		<div className='parent'>
			<div style={{display:'flex',flexDirection:'column',gap:'20px',textAlign:'start',padding:'15px'}}>
				{data.map((it)=>(
					<span key={it?.id} className='cateName' 
						onClick={()=>(
							navigate(it?.path)
						)}
					>{it?.name}</span>
				))}
			</div>
		</div>
	);
};

export default BoxNav;